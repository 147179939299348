import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import "./layout.css"

const Wrapper = styled.div`
  padding: 1rem;
  margin: auto;
  margin-top: 88.49px;
  @media only screen and (min-width: 576px) {
    width: 540px;
  }
  @media only screen and (min-width: 768px) {
    width: 720px;
  }
  @media only screen and (min-width: 992px) {
    width: 960px;
  }
  @media only screen and (min-width: 1200px) {
    width: 1140px;
  }
  @media only screen and (min-width: 1440px) {
    width: 1220px;
  }
`

const Layout = ({ children }) => {
  return (
    <>
      <Wrapper>
        <main>{children}</main>
      </Wrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
